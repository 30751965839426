<template>
  <div>
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader>
            <CIcon name="cil-justify-center" />
            <strong> Daily Time Records</strong>
            <div class="card-header-actions">
              <a
                href="#"
                class="card-header-action"
                rel="noreferrer noopener"
                target="_blank"
              >
                <small class="text-muted">DTR</small>
              </a>
            </div>
          </CCardHeader>
          <CCardBody>
            <form @submit.prevent="storeCSVtoDatabase">
              <div class="row ml-2">
                <!-- COMPONENT START -->
                <div class="custom-file col-md-10">
                  <label
                    class="custom-file-label"
                    id="nameFile1"
                    for="customFile1"
                    >Select File</label
                  >
                  <input
                    type="file"
                    accept="image/*"
                    class="custom-file-input"
                    ref="file_attendance"
                    @change="uploadCSV"
                    id="attendance"
                    name="attendance"
                  />
                </div>
                <!-- COMPONENT END -->
                <div class="form-group col-md-2">
                  <button
                    type="submit"
                    class="btn btn-primary mr-1"
                    v-if="!loading"
                  >
                    Upload
                  </button>
                  <button
                    class="btn btn-primary"
                    type="button"
                    disabled
                    v-if="loading"
                  >
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Uploading...
                  </button>
                </div>
              </div>
            </form>
            <CRow>
              <CCol lg="6">
                <CTableRegular :items="regularEmployees">
                  <template #header>
                    <CIcon name="cil-grid" /> Regular Employees
                    <div class="card-header-actions">
                      <a
                        href="https://coreui.io/vue/docs/components/nav"
                        class="card-header-action"
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        <small class="text-muted">list</small>
                      </a>
                    </div>
                  </template>
                </CTableRegular>
              </CCol>
              <CCol lg="6">
                <CTableCOS :items="cosEmployees">
                  <template #header>
                    <CIcon name="cil-grid" /> Contract of Services
                    <div class="card-header-actions">
                      <a
                        href="https://coreui.io/vue/docs/components/nav"
                        class="card-header-action"
                        rel="noreferrer noopener"
                        target="_blank"
                      >
                        <small class="text-muted">list</small>
                      </a>
                    </div>
                  </template>
                </CTableCOS>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import CTableRegular from './components/RegularTable.vue';
import CTableCOS from './components/COSTable.vue';
import Papa from 'papaparse';

export default {
  name: 'DTR',
  components: { CTableRegular, CTableCOS },
  data() {
    return {
      loading: false,
      attendance_logs: [],
      regularEmployees: [
        {
          BiometricID: '1',
          FirstName: 'Alma',
          LastName: 'Patron',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '2',
          FirstName: 'Geselle',
          LastName: 'Francisco',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '3',
          FirstName: 'Francis',
          LastName: 'Ilagan',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '4',
          FirstName: 'Haemaccel',
          LastName: 'Palima',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '5',
          FirstName: 'Misael',
          LastName: 'Cabang',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '13',
          FirstName: 'Julia Felisa',
          LastName: 'Martinez',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '26',
          FirstName: 'Angel',
          LastName: 'Asis',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '28',
          FirstName: 'Revelyn',
          LastName: 'Brina',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '59',
          FirstName: 'Noeme',
          LastName: 'Mani',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '32',
          FirstName: 'Criseldo',
          LastName: 'Calinawan',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '34',
          FirstName: 'Amy',
          LastName: 'Saavedra',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '36',
          FirstName: 'Fred',
          LastName: 'Amihan',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '37',
          FirstName: 'Rolyna',
          LastName: 'Trugillo',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '61',
          FirstName: 'Ever Joy',
          LastName: 'Rabadan',
          status: 'Active',
          action: '',
        },
      ],
      cosEmployees: [
        {
          BiometricID: '8',
          FirstName: 'Kathleen',
          LastName: 'Castanares',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '9',
          FirstName: 'Sarah',
          LastName: 'Moron',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '14',
          FirstName: 'Calwin',
          LastName: 'Alegre',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '15',
          FirstName: 'Daisy',
          LastName: 'Grana',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '16',
          FirstName: 'May Trinity',
          LastName: 'Narca',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '17',
          FirstName: 'Alexis Dave',
          LastName: 'Sanmiguel',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '18',
          FirstName: 'Krizia Meijel',
          LastName: 'Mellijor',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '21',
          FirstName: 'Heidi',
          LastName: 'Paradero',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '22',
          FirstName: 'Jo',
          LastName: 'Orendain',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '23',
          FirstName: 'Eleazar',
          LastName: 'Sumalinog',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '30',
          FirstName: 'Ronan',
          LastName: 'Otaza',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '38',
          FirstName: 'Bill',
          LastName: 'Baconguis',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '39',
          FirstName: 'Christine',
          LastName: 'Escol',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '46',
          FirstName: 'Maria Mercedes',
          LastName: 'Rebita',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '51',
          FirstName: 'Gladys',
          LastName: 'Concha',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '52',
          FirstName: 'Kershey Reign',
          LastName: 'Dueñas',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '53',
          FirstName: 'Mona Pia',
          LastName: 'Galing',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '57',
          FirstName: 'Orpheus',
          LastName: 'Cubillas',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '63',
          FirstName: 'JESIELYN MAE',
          LastName: 'ADO',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '66',
          FirstName: 'CEAKA ANGEL',
          LastName: 'PACOT',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '69',
          FirstName: 'KATRINA JOY',
          LastName: 'PUPOS',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '70',
          FirstName: 'ANILOU',
          LastName: 'CABARRUBIAS',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '71',
          FirstName: 'NICOL',
          LastName: 'CAPUNONG',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '72',
          FirstName: 'Lennie',
          LastName: 'Juezan',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '73',
          FirstName: 'Angelie',
          LastName: 'Tiempo',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '74',
          FirstName: 'Jenny Pearl',
          LastName: 'Eledia',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '75',
          FirstName: 'Hannah Franchesca',
          LastName: 'Tabal',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '76',
          FirstName: 'Kate Russel',
          LastName: 'Militante',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '77',
          FirstName: 'Jobelyn',
          LastName: 'Intan',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '78',
          FirstName: 'Edrin Kate',
          LastName: 'Salingay',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '80',
          FirstName: 'Florence',
          LastName: 'Lapinig',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '81',
          FirstName: 'Gelliemae',
          LastName: 'Bajan',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '82',
          FirstName: 'Ian Jones',
          LastName: 'Entenia',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '83',
          FirstName: 'Samuel',
          LastName: 'Saragena',
          status: 'Active',
          action: '',
        },
        {
          BiometricID: '84',
          FirstName: 'Alexander',
          LastName: 'Zambrano',
          status: 'Active',
          action: '',
        },
      ],
    };
  },
  mounted() {},
  methods: {
    uploadCSV(event) {
      let _this = this;

      var csv = event.target.files[0];
      var file = $('#attendance').val();
      var ext = ['csv'];

      if (file) {
        var get_ext = file.split('.');
        get_ext = get_ext.reverse();

        if ($.inArray(get_ext[0].toLowerCase(), ext) > -1) {
          const fileName = event.target.files[0].name;
          $('.custom-file-label').html(fileName);

          Papa.parse(csv, {
            complete: function(results) {
              _this.attendance_logs = results.data;
              console.log('attendance logs', _this.attendance_logs);
            },
            skipEmptyLines: true,
            header: true,
          });
        } else {
          toastr.warning('Invalid file! Please upload CSV file.');
        }
      }
    },
    storeCSVtoDatabase() {
      this.loading = true;
      axios
        .post('api/upload-dtr', {
          data: { attendances: this.attendance_logs },
        })
        .then((response) => {
          toastr.success(response.data.message);
          this.loading = false;
          $('.custom-file-label').html('');
        })
        .catch(() => {
          alert('Something went wrong!');
        });
    },
  },
};
</script>
